import React from "react";
import { Link, graphql } from "gatsby";
import Dinero from "dinero.js";

import AniLink from "gatsby-plugin-transition-link/AniLink";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";

import PageHeader from "../components/page/header";
import PageDivider from "../components/page/divider";
import PageFooter from "../components/page/footer";

import "./registry.scss";

const RegistryImage = ({ backgroundImage }) => {
  return (
    <div
      className="registry-item-image"
      style={{ backgroundImage: "url(" + backgroundImage + ")" }}
    />
  );
};

const RegistryItem = ({ sku_data }) => {
  const has_inventory = sku_data.product.metadata.inventory > 0;
  const price_human = Dinero({ amount: sku_data.price }).toFormat();

  return (
    <Col className="col-12 col-md-3 registry-item" key={sku_data.id}>
      <AniLink fade to={"/registry/" + sku_data.id} duration={0.35}>
        <RegistryImage backgroundImage={sku_data.image} />
        <h4>{sku_data.product.name}</h4>
        {has_inventory && <h5>{price_human}</h5>}
        {!has_inventory && (
          <h5>
            <i>Sold Out</i>
          </h5>
        )}
      </AniLink>
    </Col>
  );
};

const RegistryPage = ({ data }) => {
  let categories = {
    Life: [],
    Kitchen: [],
    Home: [],
    Travel: [],
    Etc: []
  };
  data.items.nodes.map(sku_data => {
    console.log(sku_data.product.active);
    if (sku_data.product.active) {
      let category = sku_data.product.metadata.category;

      const _item = <RegistryItem sku_data={sku_data} key={sku_data.id} />;
      if (category && category in categories) {
        categories[category].push(_item);
      } else {
        categories["Etc"].push(_item);
      }
    }
  });

  return (
    <Layout page="registry">
      <SEO title="Registry" />

      <PageHeader title={data.content.childPagesYaml.title} />

      <Row className="page-content">
        <Col sm />
        <Col className="col-12 col-sm-10">
          <p>
            We like all sorts of things from all sorts of stores. In order to
            feature this diverse set of objects and experiences, we built our
            own registry. If you're curious about anything, please reach
            out:&nbsp;
            <a href="mailto:awmmcb@fastmail.com">awmmcb@fastmail.com</a>
          </p>
        </Col>
        <Col sm />
      </Row>

      <Row className="page-content">
        <Col sm />
        <Col className="col-12 col-sm-10">
          <Row>
            <Col>
              <h3>Life</h3>
            </Col>
          </Row>
          <Row>{categories["Life"]}</Row>
        </Col>
        <Col sm />
      </Row>

      <Row className="page-content">
        <Col sm />
        <Col className="col-12 col-sm-10">
          <Row>
            <Col>
              <h3>Kitchen</h3>
            </Col>
          </Row>
          <Row>{categories["Kitchen"]}</Row>
        </Col>
        <Col sm />
      </Row>

      <Row className="page-content">
        <Col sm />
        <Col className="col-12 col-sm-10">
          <Row>
            <Col>
              <h3>Home</h3>
            </Col>
          </Row>
          <Row>{categories["Home"]}</Row>
        </Col>
        <Col sm />
      </Row>

      <Row className="page-content">
        <Col sm />
        <Col className="col-12 col-sm-10">
          <Row>
            <Col>
              <h3>Travel</h3>
            </Col>
          </Row>
          <Row>{categories["Travel"]}</Row>
        </Col>
        <Col sm />
      </Row>

      <Row className="page-content">
        <Col sm />
        <Col className="col-12 col-sm-10">
          <Row>
            <Col>
              <h3>Et Cetera</h3>
            </Col>
          </Row>
          <Row>{categories["Etc"]}</Row>
        </Col>
        <Col sm />
      </Row>

      <PageFooter />
    </Layout>
  );
};

export const query = graphql`
  {
    content: file(relativePath: { eq: "pages/registry.yml" }) {
      childPagesYaml {
        title
        description
      }
    }
    items: allStripeSku {
      nodes {
        id
        image
        price
        product {
          metadata {
            category
            description
            inventory
          }
          active
          name
        }
      }
    }
  }
`;

export default RegistryPage;
